<template>
  <div>
    <router-link class="come-back" to="/admin/normative_base">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование нормативной базы' : 'Создание нормативной базы' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="AddNormativBase" ref="AddNormativBase">
        <div class="content-sb">
          <p>Название пункта<span>*</span></p>
          <input required placeholder="Введите название пункта" v-model="form.title" type="text" name="title" class="input blue">
        </div>
      <div class="content-sb">
        <p>Название пункта (Англ)</p>
        <input placeholder="Введите название пункта (Англ)" v-model="form.titleEng" type="text" name="titleEng" class="input blue">
      </div>

      <div v-if="$route.query.type === 'edit' && form.file !== null" class="content-sb">
        <p>Старый документ<span>*</span></p>
        <a target="_blank" :href="form.file">Документ</a>
      </div>
      <div v-if="$route.query.type === 'edit' && form.link" class="content-sb">
        <p>Старая ссылка<span>*</span></p>
        <a target="_blank" :href="form.link">Ссылка</a>
      </div>

      <div class="content-sb">
        <p><b>Добавьте ссылку на сторонний ресурс или документ</b><span>*</span></p>
        <p></p>
      </div>

      <div class="content-sb">
        <p>Ссылка на страницу<span>*</span></p>
        <input placeholder="Вставьте ссылку на сторонний ресурс" v-model="form.link" type="text" class="input blue">
      </div>

        <div v-if="form.link.length === 0" class="content-sb">
          <p>Документ<span>*</span></p>
          <input  type="file" name="file"  class="input blue">
        </div>

      <div class="content-sb">
        <p>Впишите названия разделов, под которыми<br/> должна отображаться эта запись </p>
        <tags @dataTags="dataTags" :startValue="form.tagList" placeholder="Впишите названия разделов" />
      </div>
      <div class="content-sb">
        <p>Впишите названия разделов, под которыми<br/> должна отображаться эта запись (Англ)</p>
        <tags @dataTags="dataTagsEng" :startValue="form.tagListEng" placeholder="Впишите названия разделов (Англ)" />
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/normative_base" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import Tags from '@/components/Admin/Tags'

export default {
  name: 'createCorruption',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailNormativeBase : {
        tagList: [],
        tagListEng: [],
        title: '',
        titleEng: '',
        link: ''
      }
    }
  },
  methods: {
    AddNormativBase () {
      const formData = new FormData(this.$refs.AddNormativBase)
      formData.append('tagList', JSON.stringify(this.form.tagList))
      formData.append('tagListEng', JSON.stringify(this.form.tagListEng))
      if (this.form.link) formData.append('link', this.form.link)
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editNormativeBase', { data: formData, id: this.form.id })
      } else {
        this.$store.dispatch('createNormativeBase', formData)
      }
    },
    dataTags (value) {
      this.form.tagList = value
    },
    dataTagsEng (value) {
      this.form.tagListEng = value
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailNormativeBase.link === null) {
      this.form.link = ''
    }
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailNormativeBase === null) {
      this.$router.push('/admin/normative_base')
    }
  },
  components: {
    TricolorLine,
    Tags
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
